.user-modal .MuiDialog-paper {
    color: #fff;
    width: 336px;
    margin: 5px;
}

.user-modal .MuiDialogContent-root {
    /* width: 370px; */
    display: flex;
    flex-direction: column;
    padding: 32px;
    padding-top: 32px !important;
}

.user-modal .user-modal__close {
    fill: #3e374f80;
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.user-modal .user-modal__title {
    margin: 5vh 0 1.5vh 0;
    color: #3f2188;
}

.user-modal .lnkPasswordRecover {
    text-align: right;
}
.user-modal .lnkPasswordRecover a {
    text-decoration: none;
    font-size: 0.8em;
    color: #7f7b89;
}

.user-modal .userModal__callToActionLink {
    margin-bottom: 5vh;
    color: #7f7b89;
}

.user-modal .userModal__callToActionLink a {
    color: #7f7b89;
}

.user-modal .userModal__callToActionBtn {
    margin: 1.75rem 0 2rem 0;
    padding: 0.875rem 4.5rem;
}

.user-modal input:-webkit-autofill {
    -webkit-background-clip: text;
    -webkit-text-fill-color: black !important;
}
