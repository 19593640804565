.productDescription {
    margin-top: 4rem;
}

.productDescription .productDescription__title {
    font-weight: 700;
    margin-bottom: 1rem;
}

.productDescription__text {
    font-weight: 400;
    color: #7f7b89;
}
