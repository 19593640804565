.categoryList {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
}

@media (min-width: 1024px) {
    .categoryList {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1.875rem;
    }

    .categoryList .category {
        flex-grow: 1;
        max-width: calc(100% * (1 / 3) - 20px);
    }
}
