.categoryMenu {
    min-width: 300px;
}

.categoryMenu .categoryMenu__title {
    font-weight: 700;
    font-family: 'Muli';
    color: #292239;
}

.categoryMenu__openButton.MuiIconButton-root {
    padding: 0;
}

.categoryMenu .categoryMenu__menuIcon {
    font-size: 1.3em;
    padding: 0;
}

.categoryMenu .categoryMenu__backButton {
    text-transform: none;
    height: 20px;
    margin-bottom: 1em;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 1.8em;
}

.categoryMenu .categoryMenu__backButton svg {
    height: 16px;
    margin: 0.15em 0;
    margin-right: 0.2em;
}

.categoryMenu .categoryMenu__title {
    display: flex;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 1.5em;
    align-items: center;
    font-family: 'Muli', sans-serif;
}

.categoryMenu .categoryMenu__title span {
    line-height: 1.2em;
}

.categoryMenu .categoryMenu__title svg {
    height: 18px;
    margin: 0.15em 0;
    margin-right: 0.2em;
}

.categoryMenu .categoryMenu__option {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0.5em 0em 1em 0em;
    text-decoration: none;
    font-weight: bold;
    line-height: 24.5px;
    font-size: 16px;
}

.categoryMenu .categoryMenu__subOption {
    font-weight: lighter;
    font-size: 15px;
    font-family: 'Noto Sans';
    margin-left: 1rem;
}

.categoryMenu .categoryMenu__list {
    padding-left: 0px;
}

.categoryMenu .categoryMenu__option span {
    display: flex;
}

.categoryMenu__option svg.MuiSvgIcon-root {
    height: 16px;
    margin: 0.15em 0;
    margin-right: 0.2em;
}

.categoryMenu .categoryMenu__option > svg.MuiSvgIcon-root > path {
    fill: #e6e5e8;
}

.categoryMenu .categoryMenu__option.active a {
    font-weight: 700;
}
.categoryMenu .categoryMenu__option.inactive a {
    font-weight: 400;
}

.categoryMenu .categoryMenu__option a {
    border-bottom: none;
    line-height: 1em;
    color: inherit;
    text-decoration: none;
    font: inherit;
}

.categoryMenu .categoryMenu__option a:hover,
.categoryMenu__option a:active {
    cursor: pointer;
    color: inherit;
    text-decoration: underline;
}

.categoryMenu .categoryMenu__option.usernameMenuOption {
    margin-bottom: 2em;
    font-size: 15px;
    text-align: center;
}

.categoryMenu .categoryMenu__option.usernameMenuOption span {
    line-height: 1.2em;
}

.categoryMenu .categoryMenu__option.usernameMenuOption div {
    display: flex;
    align-items: center;
}

.categoryMenu .categoryMenu__option.usernameMenuOption svg {
    height: 18px;
}

.categoryMenu .categoryMenu__forwardButton:hover,
.categoryMenu .categoryMenu__forwardButton:active {
    transform: translateX(5px);
    transition: all 6ms;
    cursor: pointer;
}
