body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: white !important;
}
