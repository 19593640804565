#homeCategories {
    margin-top: 5rem;
}

.homeCategories__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;
}

.homeCategories__categories{
    display:flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 2rem;
    width: 100%;
}

.homeCategories__more {
    display: flex;
    justify-content: center;
    margin: 2rem auto 0 auto !important;
    vertical-align: bottom;
}

.homeCategories__more {
    text-decoration: none;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
}

.homeCategories__more a:hover {
    text-decoration: underline;
}

@media(min-width:1024px){
    #homeCategories {
        margin-top: 7.5rem;
    }
    
    .homeCategories__categories {
        order: 2;
    }

    .homeCategories__more {
        display: flex;
        order: 1;  
        margin: 0 !important;  
        line-height: 36px;
    }
}