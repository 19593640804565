.category {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background: mediumpurple;
    height: 150px;
    border-radius: 20px !important;
    margin: 0 !important;
}

.category button {
    height: 100%;
}

.category .category__title {
    text-shadow: 2px 4px 15px #3F2188;
    color:#fff;
    margin-left: 16px;
    font-weight: 800;
    max-width: 50%;
}

@media(min-width:1024px){
    .category {
        height: 300px;
    }

}