.cartBag__container {
    padding: 0;
    margin-top: 5rem;
}
.cartBag__header {
    display: flex;
    align-items: center;
}

.cartBag__container .cartBag__viewBagLink {
    display: none;
}

.cartBag__container .cartBag__title {
    font-size: 1.5rem;
    margin-right: 0.7rem;
}

.cartBag__container .cartBag__numItems {
    margin-left: auto;
    font-size: 1rem;
    color: #7f7b89;
}

.cartBag__total {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.5rem;
}

.cartBag__items {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 2rem;
}

.cartBag__items > div:not(:first-child) {
    border-top: 2px solid #e6e5e8;
}
.cartBag__footer {
    border-top: 2px solid #e6e5e8;
    padding: 2rem 0 1.5rem 0;
}

@media (min-width: 1024px) {
    .cartBag__header {
        display: flex;
        padding-bottom: 1rem;
    }

    .cartBag__container {
        padding: 24px;
        margin-top: 0;
        max-width: 348px;
        min-width: 300px;
    }

    .cartBag__container .cartBag__numItems {
        margin-left: 0;
        font-size: 1.125rem;
    }

    .cartBag__container .cartBag__viewBagLink {
        display: flex;
        margin-left: auto;
        align-items: center;
    }
}
