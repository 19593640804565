.breadcrumbs.MuiBreadcrumbs-root {
    margin-bottom: 2rem;
}


.breadcrumbs a {
    font-size: 14px;
}

.breadcrumbs p {
    font-size: 15px;
}