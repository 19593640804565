.product {
    border-radius: 15px;
    margin: 0 !important;
    position: relative;
}

.product_favorite-btn {
    color: #9e8dc3 !important;
    position: absolute !important;
    right: 0;
    z-index: 1;
}

.product_favorite-btn--selected {
    color: #9e8dc3 !important;
}

.product__img {
    height: 140px;
    border-radius: 15px;
}

.product__title {
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
}

.product .product__price {
    font-size: 1rem;
}
