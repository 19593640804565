.filterModal__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.filterModal__order .filterModal__orderLabel {
    margin-bottom: 1.5rem;
    color: #292239;
    font-family: 'Muli';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
}
