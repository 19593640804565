.productList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;
    flex-basis: 100%;
    margin-bottom: 2rem;
}

.productList > div {
    flex-basis: calc(50% - 0.5rem);
}

@media (min-width: 1024px) {
    .productList {
        flex-wrap: nowrap;
        gap: 1.875rem;
    }

    .productList > div {
        flex: 1 1 0px;
    }
}
