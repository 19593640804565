section#homeLanding{
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 79%;
}

.homeLanding__container {
    position: relative;
    height: 90vh;
    border-radius: 10px;
}

.homeLanding__content {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 20%;
    align-items: flex-start;
}

.homeLanding__image-container {
    position: absolute;
    z-index: -1;
    top: 0;
    height: 100vh;

}

.homeLanding__image-container img {
    height: 100%;
}

/* .homeLanding__container {
    background-image: url('images/cover-desktop.png');
    background-size: cover;
    background-repeat: no-repeat;
} */
.homeLanding__title {
    font-size: 2.4em;
    font-family: 'Muli';
    font-weight: 900;
    color: #fff;
    margin: 0;
}

#homeLanding .homeLanding__description {
    color: #fff;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
}

.homeLanding__btn {
    font-weight: bold;
    font-family: 'Muli';
    font-size: 1em;
    border-radius: 2em;
    padding: 0.9em 3.5em;
    background-color: #3f2188;
    border: 0em;
    color: #fff;
    text-decoration: none;
}

section#homeLanding img{
    width: 100%;
}

section#homeLanding Select {
    color: #3f2188;
    float: right;
    text-align: -webkit-center;
    margin-right: 3em;
}

@media(min-width:1024px){
    .homeLanding__container {
        height: 624px;
        margin-top: 2.5rem !important;
    }

    .homeLanding__content {
        max-width: 90.75%;
        left: 5rem;
        bottom: 25%;
        max-width: 20rem;
        font-size: 18px;
    }

    .homeLanding__title {
        font-size: 2.65em;
    }
}