.mobile-menu__openButton.MuiIconButton-root {
    padding: 0;
    color: #fff;
}

.mobile-menu__dialog .mobile-menu__menuIcon {
    color: #fff;
    font-size: 1.3em;
    padding: 0;
}

.mobile-menu__dialog .mobile-menu__backButton {
    text-transform: none;
    fill: white;
    color: #fff;
    height: 20px;
    margin-bottom: 1em;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 1.8em;
}

.mobile-menu__dialog .mobile-menu__backButton svg {
    height: 16px;
    margin: 0.15em 0;
    margin-right: 0.2em;
}

.mobile-menu__dialog .mobile-menu__title {
    display: flex;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 1.5em;
    align-items: center;
    font-family: 'Mulish', sans-serif;
}

.mobile-menu__dialog .mobile-menu__title span {
    line-height: 1.2em;
}

.mobile-menu__dialog .mobile-menu__title svg {
    height: 18px;
    margin: 0.15em 0;
    margin-right: 0.2em;
}

.mobile-menu__dialog .mobile-menu__option {
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: #fff;
    margin: 0.5em 0em 1em 0em;
    text-decoration: none;
    font-weight: bold;
    font-family: 'Mulish';
    line-height: 24.5px;
    font-size: 16px;
}

.mobile-menu__dialog .mobile-menu__option a.cntSubCategoriesMenu {
    font-size: 15px;
    font-family: 'Noto Sans';
    font-weight: 400;
    line-height: normal;
}
.mobile-menu__dialog .mobile-menu__list {
    padding-left: 0px;
}

.mobile-menu__dialog .mobile-menu__option span {
    display: flex;
}

.mobile-menu__option svg.MuiSvgIcon-root {
    height: 16px;
    margin: 0.15em 0;
    margin-right: 0.2em;
}

.mobile-menu__dialog .mobile-menu__option > svg.MuiSvgIcon-root > path {
    fill: #e6e5e8;
}

.mobile-menu__dialog .mobile-menu__option a {
    border-bottom: none;
    line-height: 1em;
    color: inherit;
    text-decoration: none;
    font: inherit;
}

.mobile-menu__dialog .mobile-menu__option a:hover,
.mobile-menu__option a:active {
    cursor: pointer;
    color: inherit;
    text-decoration: underline;
}

.mobile-menu__dialog .mobile-menu__option.usernameMenuOption {
    margin-bottom: 2em;
    font-size: 15px;
    text-align: center;
}

.mobile-menu__dialog .mobile-menu__option.usernameMenuOption span {
    line-height: 1.2em;
}

.mobile-menu__dialog .mobile-menu__option.usernameMenuOption div {
    display: flex;
    align-items: center;
}

.mobile-menu__dialog .mobile-menu__option.usernameMenuOption svg {
    height: 18px;
}

.mobile-menu__dialog .mobile-menu__forwardButton:hover,
.mobile-menu__dialog .mobile-menu__forwardButton:active {
    transform: translateX(5px);
    transition: all 6ms;
    cursor: pointer;
}

/* Profile User Mobile Area */

.cntButtonsProfileMenu {
    display: flex;
    width: 90%;
    justify-content: center;
    margin: 2em 0em 0em 0em;
}

.cntButtonsProfileMenu .signUp,
.cntButtonsProfileMenu .logIn {
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    border: none;
    flex: 1;
    border-radius: 25px;
    padding: 0.5em 1em 0.5em 1em;
    margin: 0em 0.5em 0em 0.5em;
    font-weight: bold;
    cursor: pointer;
}
.cntButtonsProfileMenu .signUp {
    border: 2px solid #fff;
    color: #fff;
    background: none;
}
