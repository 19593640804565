.orderSelect .orderSelect__input {
    display: flex;
    gap: 0.25rem;
    font-size: 1rem;
    width: 320px;
}
.orderSelect__input strong {
    font-size: 0.95rem;
}

.orderSelect .MuiSelect-select:focus {
    background-color: transparent;
}

.orderSelect fieldset {
    border: 0px;
}

#menu- .MuiListItem-root.Mui-selected,
#menu- .MuiListItem-root.Mui-selected:hover {
    background-color: white;
    font-weight: bolder;
}
