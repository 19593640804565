.contactForm {
    display: flex;
    flex-direction: column;
}

.contactForm__field label,
.contactForm__field input {
    color: white;
}

.contactform__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 1.5rem;
    width: 100%;
}
.contactForm .contactForm__btn {
    background: #FFFFFF;
    border-radius: 60px;
    color: #3F2188;
    margin-left: auto;
}

.contactForm .contactForm__btn:hover {
    background-color: #f3f3f3;
}
.contactForm .contactForm__btn:disabled {
    background-color: #e1e1e1 !important;
}

.contactForm .contactForm__btn.contactForm__loadingBtn {
    padding: 1rem 4.84rem !important;
}

.contactForm_field .MuiOutlinedInput-notchedOutline {
    border-color: white;
}

@media(min-width: 1024px) {
    .contactForm {
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1rem;
    }
    .contactForm__field {
        flex: 1 1 100%;
    }
    .contactForm__name, .contactForm__email {
        flex: 1 1 48%;
    }

}