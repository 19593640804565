.logo__container {
    margin: 0 auto;
}

.logo__image {
    width: 125px;
    height: auto;
    margin: 5px 0px 0 0;
}

@media (min-width: 1024px) {
    .logo__image {
        width: 140px;
        margin: 10px 8px 0 0;
    }

    .logo__container {
        margin: unset;
    }
}
