.howItWorks {
    margin-top: 5rem;
    background: #cdcbd080;
}

.howItWorks__container {
    border-radius: 20px;
}

.howItWorks__content {
    display: flex;
    flex-direction: column;
}

.howItWorks__introduction {
    margin-top: 3.75rem;
}

.howItWorks .howItWorks__title {
    margin-bottom: 1.5rem;
}

.howItWorks .howItWorks__list {
    margin-top: 2.75rem;
    margin-bottom: 1rem;
}

.howItWorks__list li {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0;
    margin-bottom: 1.75rem;
}

.howItWorks__listItem img {
    width: 80px;
    height: 80px;
    margin-bottom: 1.5rem;
}

.howItWorks__listItem span {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    color: #3F2188;
    margin-bottom: 8px;

}

.howItWorks__listItem p {
    font-size: 20px;
}

@media(min-width:1024px){
    .howItWorks {
        background: none;
        margin-top: 7.5rem;
    }
    
    .howItWorks__container {
        background: #cdcbd080;
    }
    
    .howItWorks__content {
        flex-direction: row;
        padding: 2rem 5rem;
        gap: 3rem;
    }

    .howItWorks .howItWorks__title {
        font-size: 48px;
    }

    .howItWorks__list li {
        flex-direction: row;
    }

    .howItWorks__listItem img {
        margin-right: 1.5rem;
    }

}

