.mainHeader {
    position: fixed;
}

.mainHeader__navbar {
    display: flex;
    flex-wrap: wrap;
}

.mainHeader__menu {
    display: flex;
    flex: 1;
    justify-content: space-between;
}

.mainHeader__icons {
    display: flex;
}

.mainHeader__icons .mainHeader__profile-icon {
    display: none;
}

.mainHeader .close {
    display: none;
}

.mainHeader__logo {
    margin: 0 auto;
}

.mainHeader .cntIcones {
    padding-top: 0.7em;
    font-size: 1.2em;
    position: fixed;
    right: 0;
}

.mainHeader .cntIcones a i {
    color: #fff;
    padding: 0em 0.5em 0em 0.5em;
    font-size: 1.4em;
}

.mainHeader__create {
    display: none;
}

@media (min-width: 1024px) {
    .mainHeader__menu button {
        display: none;
    }

    .mainHeader__logo {
        margin: 0;
    }
    .mainHeader__search {
        flex-basis: 320px;
        align-items: center;
        padding: 0;
        order: 0;
    }

    .mainHeader__icons {
        order: 2;
    }

    .mainHeader__icons .mainHeader__profile-icon {
        display: flex;
    }

    .mainHeader__create {
        display: flex;
        align-items: center;
        order: 1;
        margin: 0 2rem;
    }
}
