.cartModal__closeButton {
    padding: 0px !important;
    margin: 0em 0em 0em 0em;
}

.cartModal__closeButton .cartModal__closeIcon {
    padding: 0px;
    color: #fff;
    font-size: 1.2em;
}
