section#shop {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

section#shop .shop__title {
    margin-bottom: 1.5rem;
    color: #3f2188;
}

@media (min-width: 1024px) {
    section#shop .shop__title {
        margin-bottom: 2rem;
    }
}
