@import url('https://use.typekit.net/spq0mis.css');
@font-face {
    font-family: 'Noto Sans';
    src: local('NotoSans-Regular'), url('./fonts/NotoSans/NotoSans-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Mulish'; /*Can be any text*/
    src: local('Mulish-Bold'), url('./fonts/Mulish/Mulish-Bold.ttf') format('truetype');
}

#root {
    background: #fafafa;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.App {
    text-align: center;
}

.MuiCircularProgress-colorPrimary {
    color: #3f2188;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.standard-container {
    width: 100%;
    max-width: calc(100% - 24px);
    margin: 0 auto;
    padding: 0;
}

@media (min-width: 1024px) {
    .standard-container {
        max-width: 90.75%;
    }
}

@media (min-width: 1440px) {
    .standard-container {
        max-width: 1290px;
    }
}
