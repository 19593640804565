footer {
    width: 100%;
    background-color: #3f2188;
    display: flex;
    margin-top: auto;
}

footer .footer__container {
    margin-top: 5rem;
    margin-bottom: 6.25rem;
}

.footer__links {
    display: flex;
    justify-content: space-between;
}

.footer__links-column {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    flex-basis: 50%;
}
.footer__logo {
    color: #fff;
    margin-bottom: 2.5rem;
    font-family: 'Muli';
    font-style: normal;
    font-weight: 900;
    font-size: 1.25rem;
    line-height: 25px;
}
footer a {
    color: #fff;
    text-decoration: none;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}

footer a:hover,
footer a:active {
    text-decoration: underline;
}

@media (min-width: 1024px) {
    footer .footer__container {
        display: flex;
    }

    .footer__logo {
        flex-basis: 35%;
        font-size: 1.5rem;
    }

    .footer__links {
        flex-basis: 65%;
    }

    footer a {
        font-size: 18px;
    }
}
