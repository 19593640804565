.search__container {
    display: flex;
    flex-direction: column;
}

.search__headerResults {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
}

.search__container .search__order {
    font-size: 1.125rem;
}

.search__categoryTabs {
    margin-bottom: 1.5rem;
}

.search__categoryTabs .MuiTab-root {
    text-transform: none;
}

.search__results {
    width: 100%;
    min-height: 60vh;
}

.search__pagination {
    display: flex;
    justify-content: center;
    margin: 5rem 0;
}

.search__pagination .MuiPagination-ul{
    flex-wrap: nowrap;
}

.search__pagination .MuiPagination-ul li {
    padding: 0px;
}

@media (min-width: 1024px) {
    .search__container {
        flex-direction: row;
        gap: 1.875rem;
    }

    .search__headerResults {
        margin-bottom: 2rem;
    }

    .search__productList {
        flex: auto;
        flex-wrap: wrap;
    }

    .search__productList > div {
        flex-basis: calc(31% - 0.5rem);
        flex-grow: 0;
    }
}
