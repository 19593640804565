@import url(https://use.typekit.net/spq0mis.css);
#privacy {
   margin-top: 5rem;
   margin-bottom: 6rem; 
   padding-top: 5rem;
}

#privacy .privacy__title {
    color: #292239;
    margin-bottom: 2.5rem;
}

#privacy .privacy__subtitle {
    color: #292239;
    font-family: 'Muli';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 1rem;
}

#privacy .privacy__text {
    color: #7F7B89;
    margin-bottom: 2.25rem;
    font-family: 'Muli';
    font-size: 18px;
}
.productTags__container {
    order: 4;
    margin-top: 3.75rem;
}
.productTags__tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.productTags__tag {
    background-color: #3f2188;
    color: white;
    padding: 6px 16px;
    border-radius: 5px;
}

.productTags__container .productTags__title {
    font-weight: 700;
    margin-bottom: 1rem;
}

.productDescription {
    margin-top: 4rem;
}

.productDescription .productDescription__title {
    font-weight: 700;
    margin-bottom: 1rem;
}

.productDescription__text {
    font-weight: 400;
    color: #7f7b89;
}

.imagesSlider__container {
    width: 100%;
}

.imagesSlider__indicator {
    cursor: pointer;
    padding: 5px 10px;
    text-align: center;
    border: 1px #3f2188 solid;
    margin: 0;
}

.imagesSlider__indicator.active {
    color: #fff;
    background: #3f2188;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

.productDetail__priceAndQuantityContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2.09rem;
}

.productDetail__priceContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.productDetail__price {
    color: #3f2188;
}

.productDetail__buttons {
    margin-top: 4rem;
}

.productDetail {
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
}

.productDetail__img {
    width: 100%;
    margin-bottom: 2.5rem;
}
.productDetail .productDetail__title {
    margin-bottom: 1rem;
}

.productDetail__selectors {
    display: flex;
    flex-direction: column;
}

.productDetail img {
    height: auto;
    width: 100%;
}

@media (min-width: 768px) {
    .productDetail {
        max-height: 1000px;
        grid-column-gap: 6vw;
        -webkit-column-gap: 6vw;
                column-gap: 6vw;
    }

    .productDetail > div {
        width: 100%;
    }

    .productDetail > div:nth-child(1),
    .productDetail > div:nth-child(3) {
        max-width: 56%;
    }

    .productDetail > div:nth-child(2),
    .productDetail > div:nth-child(4) {
        max-width: calc(44% - 6vw);
    }

    .productDescription {
        order: 2;
    }

    .productDetail__details {
        order: 3;
    }
}

@media (min-width: 1024px) {
    .productDetail > div:nth-child(1),
    .productDetail > div:nth-child(3) {
        width: 60%;
    }

    .productDetail img {
        height: 600px;
        width: auto;
    }
}

.product {
    border-radius: 15px;
    margin: 0 !important;
    position: relative;
}

.product_favorite-btn {
    color: #9e8dc3 !important;
    position: absolute !important;
    right: 0;
    z-index: 1;
}

.product_favorite-btn--selected {
    color: #9e8dc3 !important;
}

.product__img {
    height: 140px;
    border-radius: 15px;
}

.product__title {
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
}

.product .product__price {
    font-size: 1rem;
}

.productList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    grid-gap: 1rem;
    gap: 1rem;
    flex-basis: 100%;
    margin-bottom: 2rem;
}

.productList > div {
    flex-basis: calc(50% - 0.5rem);
}

@media (min-width: 1024px) {
    .productList {
        flex-wrap: nowrap;
        grid-gap: 1.875rem;
        gap: 1.875rem;
    }

    .productList > div {
        flex: 1 1;
    }
}

.productSimilarInterests__container .productSimilarInterests__title {
    margin-bottom: 2rem;
}

.breadcrumbs.MuiBreadcrumbs-root {
    margin-bottom: 2rem;
}


.breadcrumbs a {
    font-size: 14px;
}

.breadcrumbs p {
    font-size: 15px;
}
section#product {
    margin-top: 3rem;
    margin-bottom: 5rem;
}

.categoryMenu {
    min-width: 300px;
}

.categoryMenu .categoryMenu__title {
    font-weight: 700;
    font-family: 'Muli';
    color: #292239;
}

.categoryMenu__openButton.MuiIconButton-root {
    padding: 0;
}

.categoryMenu .categoryMenu__menuIcon {
    font-size: 1.3em;
    padding: 0;
}

.categoryMenu .categoryMenu__backButton {
    text-transform: none;
    height: 20px;
    margin-bottom: 1em;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 1.8em;
}

.categoryMenu .categoryMenu__backButton svg {
    height: 16px;
    margin: 0.15em 0;
    margin-right: 0.2em;
}

.categoryMenu .categoryMenu__title {
    display: flex;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 1.5em;
    align-items: center;
    font-family: 'Muli', sans-serif;
}

.categoryMenu .categoryMenu__title span {
    line-height: 1.2em;
}

.categoryMenu .categoryMenu__title svg {
    height: 18px;
    margin: 0.15em 0;
    margin-right: 0.2em;
}

.categoryMenu .categoryMenu__option {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0.5em 0em 1em 0em;
    text-decoration: none;
    font-weight: bold;
    line-height: 24.5px;
    font-size: 16px;
}

.categoryMenu .categoryMenu__subOption {
    font-weight: lighter;
    font-size: 15px;
    font-family: 'Noto Sans';
    margin-left: 1rem;
}

.categoryMenu .categoryMenu__list {
    padding-left: 0px;
}

.categoryMenu .categoryMenu__option span {
    display: flex;
}

.categoryMenu__option svg.MuiSvgIcon-root {
    height: 16px;
    margin: 0.15em 0;
    margin-right: 0.2em;
}

.categoryMenu .categoryMenu__option > svg.MuiSvgIcon-root > path {
    fill: #e6e5e8;
}

.categoryMenu .categoryMenu__option.active a {
    font-weight: 700;
}
.categoryMenu .categoryMenu__option.inactive a {
    font-weight: 400;
}

.categoryMenu .categoryMenu__option a {
    border-bottom: none;
    line-height: 1em;
    color: inherit;
    text-decoration: none;
    font: inherit;
}

.categoryMenu .categoryMenu__option a:hover,
.categoryMenu__option a:active {
    cursor: pointer;
    color: inherit;
    text-decoration: underline;
}

.categoryMenu .categoryMenu__option.usernameMenuOption {
    margin-bottom: 2em;
    font-size: 15px;
    text-align: center;
}

.categoryMenu .categoryMenu__option.usernameMenuOption span {
    line-height: 1.2em;
}

.categoryMenu .categoryMenu__option.usernameMenuOption div {
    display: flex;
    align-items: center;
}

.categoryMenu .categoryMenu__option.usernameMenuOption svg {
    height: 18px;
}

.categoryMenu .categoryMenu__forwardButton:hover,
.categoryMenu .categoryMenu__forwardButton:active {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
    transition: all 6ms;
    cursor: pointer;
}

.filterModal__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.filterModal__order .filterModal__orderLabel {
    margin-bottom: 1.5rem;
    color: #292239;
    font-family: 'Muli';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    margin-bottom: 1.5rem;
}

.orderSelect .orderSelect__input {
    display: flex;
    grid-gap: 0.25rem;
    gap: 0.25rem;
    font-size: 1rem;
    width: 320px;
}
.orderSelect__input strong {
    font-size: 0.95rem;
}

.orderSelect .MuiSelect-select:focus {
    background-color: transparent;
}

.orderSelect fieldset {
    border: 0px;
}

#menu- .MuiListItem-root.Mui-selected,
#menu- .MuiListItem-root.Mui-selected:hover {
    background-color: white;
    font-weight: bolder;
}

.search__container {
    display: flex;
    flex-direction: column;
}

.search__headerResults {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: space-between;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
}

.search__container .search__order {
    font-size: 1.125rem;
}

.search__categoryTabs {
    margin-bottom: 1.5rem;
}

.search__categoryTabs .MuiTab-root {
    text-transform: none;
}

.search__results {
    width: 100%;
    min-height: 60vh;
}

.search__pagination {
    display: flex;
    justify-content: center;
    margin: 5rem 0;
}

.search__pagination .MuiPagination-ul{
    flex-wrap: nowrap;
}

.search__pagination .MuiPagination-ul li {
    padding: 0px;
}

@media (min-width: 1024px) {
    .search__container {
        flex-direction: row;
        grid-gap: 1.875rem;
        gap: 1.875rem;
    }

    .search__headerResults {
        margin-bottom: 2rem;
    }

    .search__productList {
        flex: auto;
        flex-wrap: wrap;
    }

    .search__productList > div {
        flex-basis: calc(31% - 0.5rem);
        flex-grow: 0;
    }
}

section#shop {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
}

section#shop .shop__title {
    margin-bottom: 1.5rem;
    color: #3f2188;
}

@media (min-width: 1024px) {
    section#shop .shop__title {
        margin-bottom: 2rem;
    }
}

.user-modal .MuiDialog-paper {
    color: #fff;
    width: 336px;
    margin: 5px;
}

.user-modal .MuiDialogContent-root {
    /* width: 370px; */
    display: flex;
    flex-direction: column;
    padding: 32px;
    padding-top: 32px !important;
}

.user-modal .user-modal__close {
    fill: #3e374f80;
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.user-modal .user-modal__title {
    margin: 5vh 0 1.5vh 0;
    color: #3f2188;
}

.user-modal .lnkPasswordRecover {
    text-align: right;
}
.user-modal .lnkPasswordRecover a {
    text-decoration: none;
    font-size: 0.8em;
    color: #7f7b89;
}

.user-modal .userModal__callToActionLink {
    margin-bottom: 5vh;
    color: #7f7b89;
}

.user-modal .userModal__callToActionLink a {
    color: #7f7b89;
}

.user-modal .userModal__callToActionBtn {
    margin: 1.75rem 0 2rem 0;
    padding: 0.875rem 4.5rem;
}

.user-modal input:-webkit-autofill {
    -webkit-background-clip: text;
    -webkit-text-fill-color: black !important;
}

footer {
    width: 100%;
    background-color: #3f2188;
    display: flex;
    margin-top: auto;
}

footer .footer__container {
    margin-top: 5rem;
    margin-bottom: 6.25rem;
}

.footer__links {
    display: flex;
    justify-content: space-between;
}

.footer__links-column {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    flex-basis: 50%;
}
.footer__logo {
    color: #fff;
    margin-bottom: 2.5rem;
    font-family: 'Muli';
    font-style: normal;
    font-weight: 900;
    font-size: 1.25rem;
    line-height: 25px;
}
footer a {
    color: #fff;
    text-decoration: none;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}

footer a:hover,
footer a:active {
    text-decoration: underline;
}

@media (min-width: 1024px) {
    footer .footer__container {
        display: flex;
    }

    .footer__logo {
        flex-basis: 35%;
        font-size: 1.5rem;
    }

    .footer__links {
        flex-basis: 65%;
    }

    footer a {
        font-size: 18px;
    }
}

.account-menu_button {
    padding: 0px !important;
    margin: 0em 0em 0em 1em !important;
}

.account-menu_icon {
    padding: 0px;
    color:#fff;
    font-size:'1.1em'; 
}
.cartBag__container {
    padding: 0;
    margin-top: 5rem;
}
.cartBag__header {
    display: flex;
    align-items: center;
}

.cartBag__container .cartBag__viewBagLink {
    display: none;
}

.cartBag__container .cartBag__title {
    font-size: 1.5rem;
    margin-right: 0.7rem;
}

.cartBag__container .cartBag__numItems {
    margin-left: auto;
    font-size: 1rem;
    color: #7f7b89;
}

.cartBag__total {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.5rem;
}

.cartBag__items {
    display: flex;
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    padding-bottom: 2rem;
}

.cartBag__items > div:not(:first-child) {
    border-top: 2px solid #e6e5e8;
}
.cartBag__footer {
    border-top: 2px solid #e6e5e8;
    padding: 2rem 0 1.5rem 0;
}

@media (min-width: 1024px) {
    .cartBag__header {
        display: flex;
        padding-bottom: 1rem;
    }

    .cartBag__container {
        padding: 24px;
        margin-top: 0;
        max-width: 348px;
        min-width: 300px;
    }

    .cartBag__container .cartBag__numItems {
        margin-left: 0;
        font-size: 1.125rem;
    }

    .cartBag__container .cartBag__viewBagLink {
        display: flex;
        margin-left: auto;
        align-items: center;
    }
}

.header {
    background: #3f2188;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 50;
}

.logo__container {
    margin: 0 auto;
}

.logo__image {
    width: 125px;
    height: auto;
    margin: 5px 0px 0 0;
}

@media (min-width: 1024px) {
    .logo__image {
        width: 140px;
        margin: 10px 8px 0 0;
    }

    .logo__container {
        margin: unset;
    }
}

.navbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.quantitySelectorItem__container {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 2.5rem;
}

.quantitySelectorItem__container .quantitySelectorItem__title {
    font-size: 1.125rem;
}

.quantitySelectorItem__container .quantitySelectorItem__header {
    display: flex;
}

.quantitySelectorItem__container .quantitySelectorItem__content {
    flex-basis: 100%;
}
.quantitySelectorItem__container .quantitySelectorItem__description {
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
}
.quantitySelectorItem__container .quantitySelectorItem__price {
    margin-top: 0.25rem;
    margin-left: auto;
    color: #292239;
}

.quantitySelectorItem__img {
    width: 43px;
    height: 43px;
    margin-right: 1rem;
}

.cartModal__closeButton {
    padding: 0px !important;
    margin: 0em 0em 0em 0em;
}

.cartModal__closeButton .cartModal__closeIcon {
    padding: 0px;
    color: #fff;
    font-size: 1.2em;
}

.cartItem__container {
    display: flex !important;
    justify-content: flex-start !important;
}

.cartItem__container .cartItem__title {
    font-size: 1.125rem;
}
.cartItem__container .cartItem__description,
.cartItem__container .cartItem__quantity {
    font-size: 0.875rem;
}

.cartItem__container .cartItem__quantity {
    margin-top: .5rem;
}
.cartItem__container .cartItem__price {
    font-size: 1rem;
    color: #3e374f;
    font-weight: 800;
    margin-top: 0.25rem;
}

.cartItem__img {
    width: 70px;
    height: 90px;
}

.desktop-menu {
    width: 100%;
    background: #fafafa;
    border: none;
}

#desktop-menu-dropdrown .MuiPaper-root {
    margin: 0;
}

.desktop-menu__list {
    list-style: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0;
}

.desktop-menu__list-item {
    position: relative;
    padding: 1em;
}

.desktop-menu__list-item:first-child {
    padding-left: 0;
}

.desktop-menu__list-item:last-child {
    padding-right: 0;
}

.desktop-menu__list-item--selected a {
    font-weight: bolder !important;
}

.desktop-menu__link {
    color: #7F7B89 !important;
    text-decoration: none;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
}

.desktop-menu__dropdown {
    margin-top: -1rem;
 }

.desktop-menu__dropdown-list {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    padding: 24px 24px 32px;
}
.desktop-menu__dropdown-item {
    padding: 10px 5px;
}

.desktop-menu__dropdown-item a {
    color: #7F7B89;
}

.desktop-menu__dropdown-item a:active,
.desktop-menu__dropdown-item a:hover {
    color: #06BCC1 !important;
    text-decoration: none !important;
}
div[class$="__dialog"] .MuiDialog-paper{
    background: #3f2188;
    height: 100vh;
    width: 80vw;
    color:#fff;
}

div[class$="__dialog"] .MuiDialogContent-root {
    padding: 8px 12px;    
}

div[class$="__dialog"] .MuiDialogTitle-root{
    padding: 16px 0px!important;
    font-family: 'Mulish', sans-serif;
}

div[class$="__dialog"] .MuiDialog-paperScrollPaper{
    margin: 0px 0px 0px 0px;
    max-height: initial;
}

div[class$="__dialog"] .MuiDialog-scrollPaper{
    align-items: flex-start;
    justify-content: flex-start;
}

div[class$="__dialog-header"] {
    display: flex;
    justify-content: center;
}

div[class$="__dialog-header"]  img{
    width: 125px;
    height: auto;
    margin: 5px 0px 0 0;
}

.mobile-menu__openButton.MuiIconButton-root {
    padding: 0;
    color: #fff;
}

.mobile-menu__dialog .mobile-menu__menuIcon {
    color: #fff;
    font-size: 1.3em;
    padding: 0;
}

.mobile-menu__dialog .mobile-menu__backButton {
    text-transform: none;
    fill: white;
    color: #fff;
    height: 20px;
    margin-bottom: 1em;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 1.8em;
}

.mobile-menu__dialog .mobile-menu__backButton svg {
    height: 16px;
    margin: 0.15em 0;
    margin-right: 0.2em;
}

.mobile-menu__dialog .mobile-menu__title {
    display: flex;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 1.5em;
    align-items: center;
    font-family: 'Mulish', sans-serif;
}

.mobile-menu__dialog .mobile-menu__title span {
    line-height: 1.2em;
}

.mobile-menu__dialog .mobile-menu__title svg {
    height: 18px;
    margin: 0.15em 0;
    margin-right: 0.2em;
}

.mobile-menu__dialog .mobile-menu__option {
    display: flex;
    width: 100%;
    justify-content: space-between;
    color: #fff;
    margin: 0.5em 0em 1em 0em;
    text-decoration: none;
    font-weight: bold;
    font-family: 'Mulish';
    line-height: 24.5px;
    font-size: 16px;
}

.mobile-menu__dialog .mobile-menu__option a.cntSubCategoriesMenu {
    font-size: 15px;
    font-family: 'Noto Sans';
    font-weight: 400;
    line-height: normal;
}
.mobile-menu__dialog .mobile-menu__list {
    padding-left: 0px;
}

.mobile-menu__dialog .mobile-menu__option span {
    display: flex;
}

.mobile-menu__option svg.MuiSvgIcon-root {
    height: 16px;
    margin: 0.15em 0;
    margin-right: 0.2em;
}

.mobile-menu__dialog .mobile-menu__option > svg.MuiSvgIcon-root > path {
    fill: #e6e5e8;
}

.mobile-menu__dialog .mobile-menu__option a {
    border-bottom: none;
    line-height: 1em;
    color: inherit;
    text-decoration: none;
    font: inherit;
}

.mobile-menu__dialog .mobile-menu__option a:hover,
.mobile-menu__option a:active {
    cursor: pointer;
    color: inherit;
    text-decoration: underline;
}

.mobile-menu__dialog .mobile-menu__option.usernameMenuOption {
    margin-bottom: 2em;
    font-size: 15px;
    text-align: center;
}

.mobile-menu__dialog .mobile-menu__option.usernameMenuOption span {
    line-height: 1.2em;
}

.mobile-menu__dialog .mobile-menu__option.usernameMenuOption div {
    display: flex;
    align-items: center;
}

.mobile-menu__dialog .mobile-menu__option.usernameMenuOption svg {
    height: 18px;
}

.mobile-menu__dialog .mobile-menu__forwardButton:hover,
.mobile-menu__dialog .mobile-menu__forwardButton:active {
    -webkit-transform: translateX(5px);
            transform: translateX(5px);
    transition: all 6ms;
    cursor: pointer;
}

/* Profile User Mobile Area */

.cntButtonsProfileMenu {
    display: flex;
    width: 90%;
    justify-content: center;
    margin: 2em 0em 0em 0em;
}

.cntButtonsProfileMenu .signUp,
.cntButtonsProfileMenu .logIn {
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    border: none;
    flex: 1 1;
    border-radius: 25px;
    padding: 0.5em 1em 0.5em 1em;
    margin: 0em 0.5em 0em 0.5em;
    font-weight: bold;
    cursor: pointer;
}
.cntButtonsProfileMenu .signUp {
    border: 2px solid #fff;
    color: #fff;
    background: none;
}

.searchBox {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    flex-basis: 100%;
    justify-content: center;
    padding-bottom: 0.5rem;
    position: relative;
}

.searchBox input#search{
    height: 2em;
    width: 100%;
    border: 0;
    color: #fff;
    padding: 0em 0.5em 0em 0.5em;
    border-radius: 4px;
    padding-right: 33.33px;
    background-color: #5C429A;
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}

.searchBox input::-webkit-input-placeholder{
    color:#fff;
}

.searchBox input::placeholder{
    color:#fff;
}

.searchBox input:focus-visible {
    outline: 1px transparent;
}

.searchBox__icon{
    position: absolute;
    height: 2em;
    border-radius: 0em 5em 5em 0em;
    padding: 0px 10px 0px 10px;
    border: 0;
    background-color:#5c4a88;
    color: #fff;
    background: transparent;
    font-size: 16px;
    right: 0;
}

.searchBox__icon:focus-visible {
    outline: none;
}


  @media(min-width:1024px){
     .searchBox {
        flex-basis: 320px;
        align-items: center;
        padding: 0;
        order: 0;
    }
}

.mainHeader {
    position: fixed;
}

.mainHeader__navbar {
    display: flex;
    flex-wrap: wrap;
}

.mainHeader__menu {
    display: flex;
    flex: 1 1;
    justify-content: space-between;
}

.mainHeader__icons {
    display: flex;
}

.mainHeader__icons .mainHeader__profile-icon {
    display: none;
}

.mainHeader .close {
    display: none;
}

.mainHeader__logo {
    margin: 0 auto;
}

.mainHeader .cntIcones {
    padding-top: 0.7em;
    font-size: 1.2em;
    position: fixed;
    right: 0;
}

.mainHeader .cntIcones a i {
    color: #fff;
    padding: 0em 0.5em 0em 0.5em;
    font-size: 1.4em;
}

.mainHeader__create {
    display: none;
}

@media (min-width: 1024px) {
    .mainHeader__menu button {
        display: none;
    }

    .mainHeader__logo {
        margin: 0;
    }
    .mainHeader__search {
        flex-basis: 320px;
        align-items: center;
        padding: 0;
        order: 0;
    }

    .mainHeader__icons {
        order: 2;
    }

    .mainHeader__icons .mainHeader__profile-icon {
        display: flex;
    }

    .mainHeader__create {
        display: flex;
        align-items: center;
        order: 1;
        margin: 0 2rem;
    }
}

.fallback {
    margin-top: 7rem !important;
    margin-bottom: 3rem !important;
}

.fallback pre {
    color: 'red';
    white-space: normal;
    max-width: 70vw;
}

main {
    margin-top: 5.6rem;
}

@media (min-width: 1024px) {
    main {
        margin-top: 6.799rem;
    }
}

@font-face {
    font-family: 'Noto Sans';
    src: local('NotoSans-Regular'), url(/static/media/NotoSans-Regular.eae34fa8.ttf) format('truetype');
}
@font-face {
    font-family: 'Mulish'; /*Can be any text*/
    src: local('Mulish-Bold'), url(/static/media/Mulish-Bold.84c15758.ttf) format('truetype');
}

#root {
    background: #fafafa;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.App {
    text-align: center;
}

.MuiCircularProgress-colorPrimary {
    color: #3f2188;
}

.App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 80px;
}

.App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
}

.App-title {
    font-size: 1.5em;
}

.App-intro {
    font-size: large;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.standard-container {
    width: 100%;
    max-width: calc(100% - 24px);
    margin: 0 auto;
    padding: 0;
}

@media (min-width: 1024px) {
    .standard-container {
        max-width: 90.75%;
    }
}

@media (min-width: 1440px) {
    .standard-container {
        max-width: 1290px;
    }
}

.category {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background: mediumpurple;
    height: 150px;
    border-radius: 20px !important;
    margin: 0 !important;
}

.category button {
    height: 100%;
}

.category .category__title {
    text-shadow: 2px 4px 15px #3F2188;
    color:#fff;
    margin-left: 16px;
    font-weight: 800;
    max-width: 50%;
}

@media(min-width:1024px){
    .category {
        height: 300px;
    }

}
.categoryList {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    grid-gap: 1rem;
    gap: 1rem;
}

@media (min-width: 1024px) {
    .categoryList {
        flex-direction: row;
        flex-wrap: wrap;
        grid-gap: 1.875rem;
        gap: 1.875rem;
    }

    .categoryList .category {
        flex-grow: 1;
        max-width: calc(100% * (1 / 3) - 20px);
    }
}

section#categories {
    margin-top: 3rem;
}

#categories .categories__title {
    color: #3f2188;
    margin-bottom: 1.875rem;
}

.categories__container {
    margin-bottom: 5rem;
}

.carousel__slide {
    padding: 0 15px;
}
#homeCategories {
    margin-top: 5rem;
}

.homeCategories__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;
}

.homeCategories__categories{
    display:flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 2rem;
    width: 100%;
}

.homeCategories__more {
    display: flex;
    justify-content: center;
    margin: 2rem auto 0 auto !important;
    vertical-align: bottom;
}

.homeCategories__more {
    text-decoration: none;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
}

.homeCategories__more a:hover {
    text-decoration: underline;
}

@media(min-width:1024px){
    #homeCategories {
        margin-top: 7.5rem;
    }
    
    .homeCategories__categories {
        order: 2;
    }

    .homeCategories__more {
        display: flex;
        order: 1;  
        margin: 0 !important;  
        line-height: 36px;
    }
}
.contactForm {
    display: flex;
    flex-direction: column;
}

.contactForm__field label,
.contactForm__field input {
    color: white;
}

.contactform__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 1.5rem;
    width: 100%;
}
.contactForm .contactForm__btn {
    background: #FFFFFF;
    border-radius: 60px;
    color: #3F2188;
    margin-left: auto;
}

.contactForm .contactForm__btn:hover {
    background-color: #f3f3f3;
}
.contactForm .contactForm__btn:disabled {
    background-color: #e1e1e1 !important;
}

.contactForm .contactForm__btn.contactForm__loadingBtn {
    padding: 1rem 4.84rem !important;
}

.contactForm_field .MuiOutlinedInput-notchedOutline {
    border-color: white;
}

@media(min-width: 1024px) {
    .contactForm {
        flex-direction: row;
        flex-wrap: wrap;
        grid-gap: 1rem;
        gap: 1rem;
    }
    .contactForm__field {
        flex: 1 1 100%;
    }
    .contactForm__name, .contactForm__email {
        flex: 1 1 48%;
    }

}
.homeContact {
    background: #654da0;
    display: flex;
}

.homeContact .homeContact__container {
    margin-top: 5rem;
    color: #fff;
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    margin-bottom: 5rem;
}
.homeContact .homeContact__textContent {
    max-width: 437px;
}
.homeContact .homeContact__title {
    margin-bottom: 1.5rem;
}
.homeContact .homeContact__description {
    margin-bottom: 1.5rem;
}

.homeContact__resultMessageContainer {
    padding: 4rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.homeContact__resultMessageTitle {
    max-width: 50%;
    text-align: center;
}

@media (min-width: 1024px) {
    .homeContact .homeContact__container {
        flex-direction: row;
        justify-content: space-between;
        margin-top: 6.5rem;
        margin-bottom: 6.5rem;
    }

    .homeContact .contactForm {
        width: 50%;
    }

    .homeContact__resultMessageContainer {
        padding: 16rem 0;
    }

    .homeContact__resultMessageTitle {
        max-width: 40%;
    }
}

.howItWorks {
    margin-top: 5rem;
    background: #cdcbd080;
}

.howItWorks__container {
    border-radius: 20px;
}

.howItWorks__content {
    display: flex;
    flex-direction: column;
}

.howItWorks__introduction {
    margin-top: 3.75rem;
}

.howItWorks .howItWorks__title {
    margin-bottom: 1.5rem;
}

.howItWorks .howItWorks__list {
    margin-top: 2.75rem;
    margin-bottom: 1rem;
}

.howItWorks__list li {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0;
    margin-bottom: 1.75rem;
}

.howItWorks__listItem img {
    width: 80px;
    height: 80px;
    margin-bottom: 1.5rem;
}

.howItWorks__listItem span {
    font-family: 'Muli';
    font-style: normal;
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    color: #3F2188;
    margin-bottom: 8px;

}

.howItWorks__listItem p {
    font-size: 20px;
}

@media(min-width:1024px){
    .howItWorks {
        background: none;
        margin-top: 7.5rem;
    }
    
    .howItWorks__container {
        background: #cdcbd080;
    }
    
    .howItWorks__content {
        flex-direction: row;
        padding: 2rem 5rem;
        grid-gap: 3rem;
        gap: 3rem;
    }

    .howItWorks .howItWorks__title {
        font-size: 48px;
    }

    .howItWorks__list li {
        flex-direction: row;
    }

    .howItWorks__listItem img {
        margin-right: 1.5rem;
    }

}


section#homeLanding{
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 79%;
}

.homeLanding__container {
    position: relative;
    height: 90vh;
    border-radius: 10px;
}

.homeLanding__content {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 20%;
    align-items: flex-start;
}

.homeLanding__image-container {
    position: absolute;
    z-index: -1;
    top: 0;
    height: 100vh;

}

.homeLanding__image-container img {
    height: 100%;
}

/* .homeLanding__container {
    background-image: url('images/cover-desktop.png');
    background-size: cover;
    background-repeat: no-repeat;
} */
.homeLanding__title {
    font-size: 2.4em;
    font-family: 'Muli';
    font-weight: 900;
    color: #fff;
    margin: 0;
}

#homeLanding .homeLanding__description {
    color: #fff;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
}

.homeLanding__btn {
    font-weight: bold;
    font-family: 'Muli';
    font-size: 1em;
    border-radius: 2em;
    padding: 0.9em 3.5em;
    background-color: #3f2188;
    border: 0em;
    color: #fff;
    text-decoration: none;
}

section#homeLanding img{
    width: 100%;
}

section#homeLanding Select {
    color: #3f2188;
    float: right;
    text-align: -webkit-center;
    margin-right: 3em;
}

@media(min-width:1024px){
    .homeLanding__container {
        height: 624px;
        margin-top: 2.5rem !important;
    }

    .homeLanding__content {
        max-width: 90.75%;
        left: 5rem;
        bottom: 25%;
        max-width: 20rem;
        font-size: 18px;
    }

    .homeLanding__title {
        font-size: 2.65em;
    }
}
#homeProducts {
    margin-top: 5rem;
}

.homeProducts__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;
}
.homeProducts__container .homeProducts__title {
    font-size: 2em;
    margin-bottom: 2rem;
    font-family: 'Muli';
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 130%;
    vertical-align: bottom;
}

.homeProducts__more {
    display: flex;
    justify-content: center;
    margin: 2rem auto 0 auto !important;
    vertical-align: bottom;
}

.homeProducts__more {
    text-decoration: none;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
}

.homeProducts__more a:hover {
    text-decoration: underline;
}

@media (min-width: 1024px) {
    #homeProducts {
        margin-top: 7.5rem;
    }

    .homeProducts__products {
        order: 2;
    }

    .homeProducts__more {
        display: flex;
        order: 1;
        margin: 0 !important;
        line-height: 36px;
    }
}

#createYourProduct {
    margin-top: 5rem;
}

#createYourProduct .createYourProduct__container {
    display: flex;
    flex-direction: column;
    padding-bottom: 5rem;
}

.createYourProduct__imageContainer {
    margin-bottom: 3.75rem;
}
.createYourProduct__imageContainer img {
    width: 100%;
    height: auto;
    border-radius: 15px;
}

.createYourProduct__smallText,
.createYourProduct__description {
    color: #7F7B89;
}

#createYourProduct .createYourProduct__title {
    margin: 1rem 0 1.5rem 0;
}

#createYourProduct .createYourProduct__btn {
    margin-top: 3rem;
    align-self: flex-start;
}

@media(min-width:1024px) { 

    #createYourProduct {
        margin-top: 8rem
    }

    #createYourProduct .createYourProduct__container {
        flex-direction: row;
        grid-gap: 7rem;
        gap: 7rem;
        align-items: center;
        padding-bottom: 7.5rem;
    }

    .createYourProduct__imageContainer {
        margin-right: 1rem;
        width: 50%;
    }

    .createYourProduct__textContainer {
        width: 40%;
    }
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

input:-webkit-autofill {
  -webkit-background-clip: text;
  -webkit-text-fill-color: white !important;
}

