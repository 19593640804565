section#categories {
    margin-top: 3rem;
}

#categories .categories__title {
    color: #3f2188;
    margin-bottom: 1.875rem;
}

.categories__container {
    margin-bottom: 5rem;
}
