#privacy {
   margin-top: 5rem;
   margin-bottom: 6rem; 
   padding-top: 5rem;
}

#privacy .privacy__title {
    color: #292239;
    margin-bottom: 2.5rem;
}

#privacy .privacy__subtitle {
    color: #292239;
    font-family: 'Muli';
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 1rem;
}

#privacy .privacy__text {
    color: #7F7B89;
    margin-bottom: 2.25rem;
    font-family: 'Muli';
    font-size: 18px;
}