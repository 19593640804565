#createYourProduct {
    margin-top: 5rem;
}

#createYourProduct .createYourProduct__container {
    display: flex;
    flex-direction: column;
    padding-bottom: 5rem;
}

.createYourProduct__imageContainer {
    margin-bottom: 3.75rem;
}
.createYourProduct__imageContainer img {
    width: 100%;
    height: auto;
    border-radius: 15px;
}

.createYourProduct__smallText,
.createYourProduct__description {
    color: #7F7B89;
}

#createYourProduct .createYourProduct__title {
    margin: 1rem 0 1.5rem 0;
}

#createYourProduct .createYourProduct__btn {
    margin-top: 3rem;
    align-self: flex-start;
}

@media(min-width:1024px) { 

    #createYourProduct {
        margin-top: 8rem
    }

    #createYourProduct .createYourProduct__container {
        flex-direction: row;
        gap: 7rem;
        align-items: center;
        padding-bottom: 7.5rem;
    }

    .createYourProduct__imageContainer {
        margin-right: 1rem;
        width: 50%;
    }

    .createYourProduct__textContainer {
        width: 40%;
    }
}