.productDetail__priceAndQuantityContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2.09rem;
}

.productDetail__priceContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.productDetail__price {
    color: #3f2188;
}

.productDetail__buttons {
    margin-top: 4rem;
}
