.cartItem__container {
    display: flex !important;
    justify-content: flex-start !important;
}

.cartItem__container .cartItem__title {
    font-size: 1.125rem;
}
.cartItem__container .cartItem__description,
.cartItem__container .cartItem__quantity {
    font-size: 0.875rem;
}

.cartItem__container .cartItem__quantity {
    margin-top: .5rem;
}
.cartItem__container .cartItem__price {
    font-size: 1rem;
    color: #3e374f;
    font-weight: 800;
    margin-top: 0.25rem;
}

.cartItem__img {
    width: 70px;
    height: 90px;
}
