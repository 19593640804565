#homeProducts {
    margin-top: 5rem;
}

.homeProducts__container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;
}
.homeProducts__container .homeProducts__title {
    font-size: 2em;
    margin-bottom: 2rem;
    font-family: 'Muli';
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 130%;
    vertical-align: bottom;
}

.homeProducts__more {
    display: flex;
    justify-content: center;
    margin: 2rem auto 0 auto !important;
    vertical-align: bottom;
}

.homeProducts__more {
    text-decoration: none;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
}

.homeProducts__more a:hover {
    text-decoration: underline;
}

@media (min-width: 1024px) {
    #homeProducts {
        margin-top: 7.5rem;
    }

    .homeProducts__products {
        order: 2;
    }

    .homeProducts__more {
        display: flex;
        order: 1;
        margin: 0 !important;
        line-height: 36px;
    }
}
