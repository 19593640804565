.quantitySelectorItem__container {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 2.5rem;
}

.quantitySelectorItem__container .quantitySelectorItem__title {
    font-size: 1.125rem;
}

.quantitySelectorItem__container .quantitySelectorItem__header {
    display: flex;
}

.quantitySelectorItem__container .quantitySelectorItem__content {
    flex-basis: 100%;
}
.quantitySelectorItem__container .quantitySelectorItem__description {
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
}
.quantitySelectorItem__container .quantitySelectorItem__price {
    margin-top: 0.25rem;
    margin-left: auto;
    color: #292239;
}

.quantitySelectorItem__img {
    width: 43px;
    height: 43px;
    margin-right: 1rem;
}
