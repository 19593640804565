.imagesSlider__container {
    width: 100%;
}

.imagesSlider__indicator {
    cursor: pointer;
    padding: 5px 10px;
    text-align: center;
    border: 1px #3f2188 solid;
    margin: 0;
}

.imagesSlider__indicator.active {
    color: #fff;
    background: #3f2188;
}
