.fallback {
    margin-top: 7rem !important;
    margin-bottom: 3rem !important;
}

.fallback pre {
    color: 'red';
    white-space: normal;
    max-width: 70vw;
}
