div[class$="__dialog"] .MuiDialog-paper{
    background: #3f2188;
    height: 100vh;
    width: 80vw;
    color:#fff;
}

div[class$="__dialog"] .MuiDialogContent-root {
    padding: 8px 12px;    
}

div[class$="__dialog"] .MuiDialogTitle-root{
    padding: 16px 0px!important;
    font-family: 'Mulish', sans-serif;
}

div[class$="__dialog"] .MuiDialog-paperScrollPaper{
    margin: 0px 0px 0px 0px;
    max-height: initial;
}

div[class$="__dialog"] .MuiDialog-scrollPaper{
    align-items: flex-start;
    justify-content: flex-start;
}

div[class$="__dialog-header"] {
    display: flex;
    justify-content: center;
}

div[class$="__dialog-header"]  img{
    width: 125px;
    height: auto;
    margin: 5px 0px 0 0;
}
