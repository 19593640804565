.searchBox {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    flex-basis: 100%;
    justify-content: center;
    padding-bottom: 0.5rem;
    position: relative;
}

.searchBox input#search{
    height: 2em;
    width: 100%;
    border: 0;
    color: #fff;
    padding: 0em 0.5em 0em 0.5em;
    border-radius: 4px;
    padding-right: 33.33px;
    background-color: #5C429A;
    font-family: 'Noto Sans';
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
}

.searchBox input::placeholder{
    color:#fff;
}

.searchBox input:focus-visible {
    outline: 1px transparent;
}

.searchBox__icon{
    position: absolute;
    height: 2em;
    border-radius: 0em 5em 5em 0em;
    padding: 0px 10px 0px 10px;
    border: 0;
    background-color:#5c4a88;
    color: #fff;
    background: transparent;
    font-size: 16px;
    right: 0;
}

.searchBox__icon:focus-visible {
    outline: none;
}


  @media(min-width:1024px){
     .searchBox {
        flex-basis: 320px;
        align-items: center;
        padding: 0;
        order: 0;
    }
}
