.productTags__container {
    order: 4;
    margin-top: 3.75rem;
}
.productTags__tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
}

.productTags__tag {
    background-color: #3f2188;
    color: white;
    padding: 6px 16px;
    border-radius: 5px;
}

.productTags__container .productTags__title {
    font-weight: 700;
    margin-bottom: 1rem;
}
