.desktop-menu {
    width: 100%;
    background: #fafafa;
    border: none;
}

#desktop-menu-dropdrown .MuiPaper-root {
    margin: 0;
}

.desktop-menu__list {
    list-style: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0;
}

.desktop-menu__list-item {
    position: relative;
    padding: 1em;
}

.desktop-menu__list-item:first-child {
    padding-left: 0;
}

.desktop-menu__list-item:last-child {
    padding-right: 0;
}

.desktop-menu__list-item--selected a {
    font-weight: bolder !important;
}

.desktop-menu__link {
    color: #7F7B89 !important;
    text-decoration: none;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
}

.desktop-menu__dropdown {
    margin-top: -1rem;
 }

.desktop-menu__dropdown-list {
    margin: 0;
    padding: 0;
    list-style: none;
    width: 100%;
    padding: 24px 24px 32px;
}
.desktop-menu__dropdown-item {
    padding: 10px 5px;
}

.desktop-menu__dropdown-item a {
    color: #7F7B89;
}

.desktop-menu__dropdown-item a:active,
.desktop-menu__dropdown-item a:hover {
    color: #06BCC1 !important;
    text-decoration: none !important;
}