.homeContact {
    background: #654da0;
    display: flex;
}

.homeContact .homeContact__container {
    margin-top: 5rem;
    color: #fff;
    display: flex;
    flex-direction: column;
    margin-top: 5rem;
    margin-bottom: 5rem;
}
.homeContact .homeContact__textContent {
    max-width: 437px;
}
.homeContact .homeContact__title {
    margin-bottom: 1.5rem;
}
.homeContact .homeContact__description {
    margin-bottom: 1.5rem;
}

.homeContact__resultMessageContainer {
    padding: 4rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.homeContact__resultMessageTitle {
    max-width: 50%;
    text-align: center;
}

@media (min-width: 1024px) {
    .homeContact .homeContact__container {
        flex-direction: row;
        justify-content: space-between;
        margin-top: 6.5rem;
        margin-bottom: 6.5rem;
    }

    .homeContact .contactForm {
        width: 50%;
    }

    .homeContact__resultMessageContainer {
        padding: 16rem 0;
    }

    .homeContact__resultMessageTitle {
        max-width: 40%;
    }
}
