.productDetail {
    padding-bottom: 5rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
}

.productDetail__img {
    width: 100%;
    margin-bottom: 2.5rem;
}
.productDetail .productDetail__title {
    margin-bottom: 1rem;
}

.productDetail__selectors {
    display: flex;
    flex-direction: column;
}

.productDetail img {
    height: auto;
    width: 100%;
}

@media (min-width: 768px) {
    .productDetail {
        max-height: 1000px;
        column-gap: 6vw;
    }

    .productDetail > div {
        width: 100%;
    }

    .productDetail > div:nth-child(1),
    .productDetail > div:nth-child(3) {
        max-width: 56%;
    }

    .productDetail > div:nth-child(2),
    .productDetail > div:nth-child(4) {
        max-width: calc(44% - 6vw);
    }

    .productDescription {
        order: 2;
    }

    .productDetail__details {
        order: 3;
    }
}

@media (min-width: 1024px) {
    .productDetail > div:nth-child(1),
    .productDetail > div:nth-child(3) {
        width: 60%;
    }

    .productDetail img {
        height: 600px;
        width: auto;
    }
}
